export const REPO_URL_MAP = {
    go: "https://github.com/nulogy/eco/tree/master/",
    PackManager: "https://github.com/nulogy/PackManager/tree/master/",
    QCloud: "https://github.com/nulogy/qcloud/tree/master/",
    "production-scheduling": "https://github.com/nulogy/production-scheduling/tree/master/",
    "Common-Platform-Interface": "https://github.com/nulogy/common-platform-interface/tree/master/",
    cn_edi: "https://github.com/nulogy/cn_edi/tree/master/",
    "eco-invoices": "https://github.com/nulogy/eco-invoices/tree/master/"
}

export const appRepoUrl = (project = "go") => {
    return REPO_URL_MAP[project]
}