import React from "react"

import {
  Box,
  Heading3,
  Link,
  List,
  ListItem,
  Sidebar,
} from "@nulogy/components"

import { friendlyAppName } from "../components/friendlyAppName"
import { appRepoUrl } from "../components/appRepoUrl"

import {
  getAllProjects,
  getFilePathsByComponentName,
  getUsageByComponent
} from "../selectors/selectors"

const SidebarProjectSection = ({ report, projectName, componentName }) => {
  const filePathList = getFilePathsByComponentName(
    report,
    projectName,
    componentName
  )
  return (
    filePathList.length > 0 && (
      <>
        <Heading3 key={projectName}>
          {friendlyAppName(projectName)}
        </Heading3>
        <Box mb="x6" key={`${projectName}-section`}>
          <List>
            {filePathList.map(filePath => (
              <ListItem key={`${filePath}-section`}>
                <Link fontSize="smaller" fontFamily="mono" href={appRepoUrl(projectName) + filePath}>
                  {filePath}
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </>
    )
  )
}

export const ComponentSidebar = ({
  report,
  currentComponent,
  isSidebarOpen,
  onClose
}) => {
  return (
    <Sidebar
      isOpen={isSidebarOpen}
      title={getUsageByComponent(report.projects, currentComponent) > 0
        ? `The ${currentComponent} component can be found in the following
    files:`
        : `The ${currentComponent} component hasn't been used anywhere yet.`}
      onClose={onClose}
    >
      {getAllProjects(report).map(project => (
        <SidebarProjectSection
          key={project}
          report={report}
          projectName={project}
          componentName={currentComponent}
        />
      ))}
    </Sidebar>
  )
}
