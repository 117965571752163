import React, { useCallback, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Flex, Box, Heading1, Input, Select, LoadingAnimation, Page } from "@nulogy/components"
import { format, parseISO } from "date-fns";
import { listReports, getReport } from "../utils/s3_reports"
import { getAllComponents } from "../selectors/selectors"
import Layout from "../components/layout"
import { ComponentsTable } from "../components/ComponentsTable"
import { ComponentSidebar } from "../components/ComponentSidebar"


const Loading = () => (
  <Flex justifyContent="center" alignItems="center" width="100%" flex="1">
    <LoadingAnimation />
  </Flex>
)

export default () => {
  const [currentComponent, setCurrentComponent] = useState("Box")
  const [search, setSearch] = useState()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [report, setReport] = useState({ date: '', nds: { exports: [] }, projects: [] })
  const [dateOptions, setDateOptions] = useState([])
  const [reportDate, setReportDate] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const handleComponentNameClick = name => {
    setCurrentComponent(name)
    setIsSidebarOpen(true)
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const handleDateChange = selectedValue => {
    setReportDate(selectedValue)
  }

  const filterComponents = () => {
    const allComponents = getAllComponents(report)
    if (search) {
      return allComponents.filter(component =>
        component.toLowerCase().includes(search.toLowerCase())
      )
    }
    return allComponents
  }

  const onClose = () => {
    setIsSidebarOpen(false)
  }

  useEffect(() => {
    const fetchDateOptions = async () => {
      const allReports = await listReports();
      const reports = allReports.map(report => ({ label: report, value: report }));
      setDateOptions(reports)
      setReportDate(reports[0].value)
    }
    fetchDateOptions()
  }, [])

  const fetchReport = useCallback(async () => {
    if (!reportDate) return
    setReport(await getReport(reportDate))
    setIsLoading(false)
  }, [reportDate]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport])

  const dateSelectOptions = dateOptions.map(({ label, value }) => ({
    label: format(parseISO(label), "MMMM dd, yyyy"),
    value
  }));

  return (
    <Layout>
      <Helmet>
        <title>Component Tracker</title>
      </Helmet>
      <Page
      >
        <Flex
          flexGrow="2"
          height="calc(100vh - 72px - 16px)"
          overflow="scroll"
          className="component-tracker"
          flexDirection="column"
        >
          {isLoading ? <Loading /> :
            <>
              <Flex flexDirection="column">
                <Heading1 mb="x4">NDS Component Tracker</Heading1>
                <Flex mb="x3">
                  <Box width="50%" mr="x3">
                    <Input
                      labelText="Component search"
                      placeholder="Type a component name..."
                      onChange={handleSearch}
                    />
                  </Box>
                  <Box width="50%" mr="x3">
                    <Select
                      onChange={handleDateChange}
                      options={dateSelectOptions}
                      value={reportDate}
                      labelText="Select a date"
                    />
                  </Box>
                </Flex>
              </Flex>
              <ComponentsTable
                projectReports={report.projects}
                components={filterComponents()}
                onClickComponent={handleComponentNameClick}
                showTotals={!Boolean(search)}
              />
            </>
          }
        </Flex>
        <ComponentSidebar
          report={report}
          currentComponent={currentComponent}
          isSidebarOpen={isSidebarOpen}
          onClose={onClose}
        />
      </Page>
    </Layout >
  )
}
