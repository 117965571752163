import React, { useState, useEffect } from "react"
import { Table, Tooltip, Link, Box, Text } from "@nulogy/components"
import {
  getUsageByComponent,
  getVersionDataByProject,
} from "../selectors/selectors"

import { friendlyAppName } from "../components/friendlyAppName"

const numericAlphabeticalSort = (a, b) =>
  String(a).localeCompare(String(b), undefined, {
    numeric: true,
    sensitivity: "base",
  })

const getSortedRows = (rows, columnKey, sortFunction) => {
  return rows.sort((a, b) => sortFunction(a[columnKey], b[columnKey]))
}

const getTableRows = (projectReports, allComponents) => {
  const allComponentsMap = {}
  projectReports.forEach(({ project, components }) => {
    components.forEach(({ files, name }) => {
      if (allComponents.includes(name)) {
        allComponentsMap[name] = {
          ...allComponentsMap[name],
          [project]: files,
        }
      }
    })
  })
  const tableRows = Object.keys(allComponentsMap).map(component => ({
    component,
    ...allComponentsMap[component],
    total: getUsageByComponent(projectReports, component),
  }))
  return tableRows
}

const getTableFooterRows = rows => {
  const rowTotalsMap = rows.reduce((acc, row) => {
    Object.keys(row).forEach(rowKey => {
      if (rowKey !== "component") {
        acc[rowKey] = (acc[rowKey] || 0) + row[rowKey]
      }
    })
    return acc
  }, {})
  const footerRows = [
    {
      component: "Total",
      ...rowTotalsMap,
    },
  ]
  return footerRows
}

const VersionTooltip = ({ versionData, children }) => {
  return versionData ? (
    <Tooltip
      placement="top-start"
      tooltip={
        <>
          <Text fontWeight="bold" fontSize="small" mb="x1">
            NDS Version Info:
          </Text>
          {versionData.map(({ version, path }) => (
            <div key={path}>
              <Text fontSize="smaller" fontWeight="bold">
                {version}
              </Text>
              <Text fontSize="smaller">({path})</Text>
            </div>
          ))}
        </>
      }
    >
      <Box>{children}</Box>
    </Tooltip>
  ) : (
    children
  )
}

export const ComponentsTable = ({
  projectReports,
  components,
  onClickComponent,
  showTotals,
}) => {
  const [sortState, setSortState] = useState({
    component: true,
    current: "component",
  })
  const [rows, setRows] = useState([])

  useEffect(() => {
    const columnKey = sortState.current
    if (columnKey) {
      const sortedRows = getSortedRows(
        getTableRows(projectReports, components),
        columnKey,
        numericAlphabeticalSort
      )
      setRows(sortState[columnKey] ? sortedRows : sortedRows.reverse())
    }
  }, [projectReports, components, sortState])

  const TableComponentName = ({ cellData, row }) => (
    <Link pl="x1" onClick={() => onClickComponent(cellData)}>
      {cellData}
    </Link>
  )

  const getTableColumns = () => {
    // Update to map multiple entries
    const allProjects = projectReports.map(({ project }) => ({
      label: project,
      dataKey: project,
      width: "80px",
    }))

    const onSortChange = dataKey => {
      return setSortState(state => ({
        ...state,
        [dataKey]: !state[dataKey],
        current: dataKey,
      }))
    }
    const columns = [
      {
        label: "Components",
        dataKey: "component",
        cellRenderer: TableComponentName,
        width: "200px",
      },
      ...allProjects,
      {
        label: "Total",
        dataKey: "total",
        width: "80px",
      },
    ]
    const columnsWithSortableHeaders = columns.map(column => ({
      ...column,
      headerFormatter: ({ label, dataKey }) => {
        const isProject = label !== "Components" && label !== "Total"
        return (
          <VersionTooltip
            versionData={
              isProject ? getVersionDataByProject(projectReports, label) : null
            }
          >
            <Table.SortingHeader
              onChange={() => onSortChange(dataKey)}
              label={isProject ? friendlyAppName(label) : label}
              ascending={!sortState[column.dataKey] || false}
              active={dataKey === sortState.current}
            />
          </VersionTooltip>
        )
      },
    }))
    return columnsWithSortableHeaders
  }
  return (
    <Table
      columns={getTableColumns()}
      rows={rows}
      compact
      keyField="component"
      noRowsContent="No components with that name found"
      footerRows={showTotals ? getTableFooterRows(rows) : []}
      stickyHeader
    />
  )
}
